import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useViewport } from "../../hooks/useViewport"
import ArmstrongCutout from "../../assets/ArmstrongCutout.svg"
import MobileContainer from "./mobile/title"
import Modal from "../../components/Modal"
import Button from "../../components/common/StandardButton"
import Video from "../../images/video-bg.png"
import { videoURL } from "../../constants"
import Close from "../../images/close.png"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
`

const Left = styled.div`
  flex-grow: 1;
  margin-left: 72px;
`

const Right = styled.div`
  flex-grow: 1;
`

const GrayBackground = styled.div`
  background-color: #f6f4f0;
  max-width: 47vw;
  margin-right: 47px;
  float: right;
  height: 100%;
  width: 100%;
`

export const FirstName = styled.div`
  @font-face {
    font-family: "Chapanza";
    src: url("../fonts/Chapanza.ttf");
  }

  @media (max-width: 768px) {
    position: unset;
    margin-top: 0;
  }

  position: absolute;
  padding-left: 8.9vw;
  margin-top: 12.1vh;
  font-family: "Chapanza";
  color: #c84836;
  font-size: 7vw;

  @media (max-width: 1360px) {
    font-size: 5vw;
  }
`

export const LastName = styled.div`
  @font-face {
    font-family: "Chapanza";
    src: url("../fonts/Chapanza.ttf");
  }

  @media (max-width: 768px) {
    position: unset;
    margin-top: 0;
  }

  margin-top: 14.5vh;
  position: absolute;
  font-family: "Chapanza";
  font-size: 12.5vw;
  font-weight: bold;
  z-index: 50;

  @media (max-width: 1360px) {
    font-size: 8.4vw;
  }
`

export const Line = styled.div`
  width: 12.3vw;
  height: 2px;
  background-color: #c84836;
  position: relative;
  margin-top: 44.1vh;
`

export const VideoWatch = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99991;
  width: 100%;
  text-align: center;

  .standard-button {
    margin: 0 auto;
    width: 200px !important;
  }

  .video-modal {
    background: #fff;
    max-width: 500px;
    margin: auto;
    position: relative;
    padding: 20px;
    z-index: 9999;
  }

  .bg-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Description = styled.div`
  margin-top: 28px;
  width: 29.3vw;
  position: absolute;

  .title {
    font-family: Chapanza;
    color: #c84836;
    font-size: 20px;
  }

  .text {
    font-family: "Lato";
    font-size: 22px;
  }

  @media (max-width: 1360px) {
    .title {
      font-size: 18px;
    }
    .text {
      font-size: 20px;
    }
  }
`

const VideoModal = ({ handleModal, open, toggleModal }) => {
  return (
    <VideoWatch
      style={{
        display: "none",
      }}
    >
      <div className="video-modal">
        <div
          onClick={toggleModal}
          style={{ width: "12px", marginLeft: "auto", cursor: "pointer" }}
        >
          <img style={{ width: "100%" }} src={Close} />
        </div>
        <h4>
          New release: The William L. Armstrong Award Film 2023. Watch Now!
        </h4>
        <img
          src={videoURL}
          alt="thumbnail of modal"
          style={{ width: "100%" }}
        />
        <Button
          cursor={"pointer"}
          onClick={handleModal}
          text={"Watch Now"}
          color="#C84836"
          styles={{ margin: "none", marginTop: "40px" }}
        />
      </div>
      <div className="bg-overlay" onClick={toggleModal}></div>
    </VideoWatch>
  )
}

const TitleCard = () => {
  const [modalOpen, setModal] = useState(false)
  const [open, setVideoModal] = useState(true)
  const { width } = useViewport()
  const breakpoint = 768

  const toggleModal = () => {
    setVideoModal(!open)
  }

  const handleModal = () => setModal(!modalOpen)
  return width <= breakpoint ? (
    <>
      <Modal
        isOpen={modalOpen}
        videoURL="https://player.vimeo.com/video/856556443?h=b29ca67059"
        onClose={handleModal}
      />
      <MobileContainer handleModal={handleModal} />
      <VideoModal
        handleModal={handleModal}
        open={open}
        toggleModal={toggleModal}
      />
    </>
  ) : (
    <>
      <Modal
        isOpen={modalOpen}
        videoURL="https://player.vimeo.com/video/856556443?h=b29ca67059"
        onClose={handleModal}
      />
      <Container>
        <Left>
          <FirstName>Bill</FirstName>
          <LastName>Armstrong</LastName>
          <Line />
          <Description>
            <div className="title">The Armstrong Project</div>
            <div className="text">
              The Armstrong Project preserves and promotes the legacy of Senator
              William Armstrong. Watch the 2023 William L. Armstrong Award film
              now!
            </div>
            <Button
              cursor={"pointer"}
              onClick={handleModal}
              text={"Watch Now"}
              color="#C84836"
              styles={{ margin: "none", marginTop: "40px" }}
            />
          </Description>
        </Left>
        <Right>
          <GrayBackground />
          <ArmstrongCutout
            style={{
              float: "right",
              zIndex: 99,
              position: "absolute",
              right: 0,
              marginTop: "35px",
              maxHeight: "100vh",
            }}
          />
        </Right>
      </Container>
      <VideoModal
        handleModal={handleModal}
        open={open}
        toggleModal={toggleModal}
      />
    </>
  )
}

export default TitleCard
